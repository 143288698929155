<template>
  <div style="height:100%">
    <!-- 有知识库权限的权限 -->
    <div class='wiki-detail' v-loading="wikiLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class='left-menu'>
        <div :class="['left-title',{'active':isCollapse}]" @click="isCollapse = !isCollapse">
          <span v-if="!isCollapse">{{title}}</span>
          <i :class="{'el-icon-s-fold':!isCollapse,'el-icon-s-unfold':isCollapse }"></i>
        </div>
        <div v-show="!isCollapse" class="left-menu-box">
          <div class="add-title">
            <span style="padding-right: 5px;">空间目录</span>
            <div style="display: flex;">
              <el-tooltip v-if="kbPermission === 2" effect="dark" content="菜单结构调整" placement="left">
                <el-link style="margin-right: 10px;" @click="openMoveDialog"><i class="el-icon-s-operation"></i></el-link>
              </el-tooltip>
              <add-popover v-if="isCreateOne" :kbId="kbId" @getTreeData="getTreeData"></add-popover>
            </div>
          </div>
          <ul style="overflow: auto;height: calc(100vh - 90px);">
            <li v-for="(item, index) in menulist" :key="index">
              <menuItem :menuItemData="item" :kbId="kbId" :isFirst="isFirst" :kbPermission="kbPermission" @getTreeData="getTreeData"/>
            </li>
          </ul>
        </div>
      </div>
      <div class='right-content'>
        <myPage v-if="catalogWiki" :isWiki="true" :kbPermission="kbPermission" :isCollapse="isCollapse"/>
        <div v-else class="noPower-div">
          <!-- <el-empty description="描述文字"></el-empty> -->
          <div class="noPower-box">
            <svg-icon icon-class="noPermission"></svg-icon>
            <span class="title-text">请先选择节点，暂无内容</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 无知识库权限的权限 -->
    <!-- <div v-else class="noPower-div">
      <div class="noPower-box">
        <svg-icon icon-class="noPermission"></svg-icon>
        <div class="title-text">没有权限访问该知识库</div>
        <p class="tip-text">你可以向该知识库<span class="owner-text">管理员</span>申请分配权限</p>
      </div>
    </div> -->
    <!-- 调整目录结构 -->
    <move-dialog :visible="moveVisible" :kbId="kbId" @close="moveVisible = false" @getTreeData="getTreeData"/>
  </div>
</template>

<script>
import myPage from '@/views/article/index.vue'
import menuItem from './componentes/menuItem.vue'
import { getTechnologyMenuFetch, getWikiPermission, getTechnologyMenuOne } from '@/api/wikiApi.js'
import { deepClone, handleTreeData, expandParents } from '@/utils/index'
import AddPopover from './componentes/addPopover.vue'
import moveDialog from './componentes/moveTree.vue'

export default {
  components: {
    myPage,
    menuItem,
    AddPopover,
    moveDialog
  },
  data() {
    return {
      isCollapse: false,
      parentId: '-1',
      headerInfo: {
        title: '',
        parentName: '',
        parentStr: '',
        updateTime: '',
        optionName: '',
        isMy: false
      },
      menulist: [],
      kbId: null,
      encryptionStr: null,
      // 0无权限 1读 2写
      kbPermission: null,
      title: '',
      isCreateOne: false,
      moveVisible: false,
      isFirst: 0,
      wikiLoading: false
    }
  },
  created() {
    if (this.$route.query.id) {
      this.kbId = Number(this.$route.query.id)
      // this.getTechnologyMenu()
      this.getPermission()
    } else {
      // 刷新浏览器 或通过链接打开知识库 需要接口获取KBId
      // this.getTechnologyMenu()
      this.getPermission()
    }
  },
  computed: {
    catalogWiki() {
      return this.$store.getters.catalogWiki
    }
  },
  watch: {
    catalogWiki: {
      handler(val) {
        if (val) {
          this.handleData(val)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getPermission() {
      getWikiPermission({
        kbId: this.kbId,
        encryptionStr: this.$route.params.pathMatch,
        dpIdStr: this.$store.getters.udId.replaceAll('-', ',')
      }).then(res => {
        this.kbPermission = res.data.permission ? res.data.permission : 0
        this.title = res.data.name ? res.data.name : ''
        // 新建空间的一级页面 isCreate1管理员和成员 0仅管理员
        if(res.isCreate === 0) {
          this.isCreateOne = res.iswikiAdmin ? true : false
        } else {
          this.isCreateOne = res.iswikiAdmin || this.kbPermission === 2 ? true : false
        }
        if (this.kbPermission === 0) {
          // 无知识库权限
          if (this.$route.params.pathMatch) {
            this.getLeftMenuOne()
          }
          // this.menulist = this.menulist.filter(item => item.encryptionStr === this.$route.params.pathMatch)
          // this.menulist = [{ encryptionStr : this.$route.params.pathMatch }]
        } else {
          this.getTechnologyMenu()
        }
        if (!this.kbId) {
          this.kbId = res.data.kbId
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleData(val) {
      const data = deepClone(this.menulist)
      function round(list) {
        list.forEach(el => {
          el.active = el.id === val
          if (el.children) {
            round(el.children)
          }
        })
      }
      round(data)
      this.$nextTick(() => {
        this.menulist = data
      })
    },
    getTechnologyMenu() {
      this.wikiLoading = true
      getTechnologyMenuFetch({
        kbId: this.kbId,
        encryptionStr: this.$route.params.pathMatch
      }).then(res => {
        this.wikiLoading = false
        if (res.code === '000000') {
          this.menulist = []
          this.menulist = res.data.length > 0 ? handleTreeData(res.data, res.parentArr ? res.parentArr : []) : []
          if (this.$route.params.pathMatch && res.data.length > 0) {
            this.selectCheck(res.data)
          } else {
            this.$store.dispatch('toggleCatalogWiki', this.menulist[0].id)
            this.$store.dispatch('setWikiMenuItem', this.menulist[0])
            this.$router.replace(this.menulist[0].encryptionStr)
          }
        }
      }).catch(() => this.wikiLoading = false)
    },
    getLeftMenuOne() {
      getTechnologyMenuOne({ encryptionStr: this.$route.params.pathMatch }).then(res => {
        if (res.code === '000000') {
          if (this.$route.params.pathMatch) {
            this.menulist = [Object.assign(res.data[0], { active: true, children: [] })]
            this.selectCheck(this.menulist)
          }
        }
      })
    },
    getTreeData() {
      this.getTechnologyMenu()
    },
    // 刷新页面 默认选中
    selectCheck(list) {
      this.$store.dispatch('toggleCatalogWiki', null)
      this.$store.dispatch('setWikiMenuItem', null)
      const checkedItem = list.filter(item => item.encryptionStr === this.$route.params.pathMatch)
      this.$store.dispatch('toggleCatalogWiki', checkedItem[0].id)
      this.$store.dispatch('setWikiMenuItem', checkedItem[0])
      // expandParents(this.menulist, checkedItem[0].id)
      this.isFirst ++
      console.log(33333333, this.isFirst, this.menulist)
    },
    openMoveDialog() {
      this.moveVisible = true
    }
  }
}
</script>

<style lang='scss' scoped>
.wiki-detail {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .right-content {
    flex-basis: 100%;
    padding: 0 25px;
  }
}
.left-menu {
  border-right: 1px solid #dee0e3;
  .left-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    // max-width: 280px;
    padding: 10px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.active {
      max-width: 40px;
      height: 60px;
    }
  }
  .left-menu-box {
    list-style-type: none;
    // max-width: 280px;
  }
  .add-title {
    padding: 0 10px;
    color: #888686;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
  }
}
</style>

<template>
  <div>
    <el-popover
      popper-class="special-popover"
      placement="bottom-start"
      width="180"
      :disabled="!hasCreatePower"
      trigger="hover">
      <div class="right-panel">
        <ul class="operate-list">
          <li class="operate-item" @click="addArticleBtn()">
            <span class="icon">
              <svg-icon icon-class="file"></svg-icon>
            </span>
            <span class="operate">新建文档</span>
          </li>
          <li class="operate-item" @click="addArticleBtn('newExcel')">
            <span class="icon">
              <svg-icon icon-class="excel_icon"></svg-icon>
            </span>
            <span class="operate">新建excle</span>
          </li>
          <li class="operate-item" @click="addArticleBtn('newDoc')">
            <span class="icon">
              <svg-icon icon-class="word"></svg-icon>
            </span>
            <span class="operate">新建word</span>
          </li>
          <li class="operate-item" @click="addArticleBtn('newPowerPoint')">
            <span class="icon">
              <svg-icon icon-class="ppt"></svg-icon>
            </span>
            <span class="operate">新建PowerPoint</span>
          </li>
          <li class="operate-item">
            <el-upload
              class="upload-demo upload_button"
              action="http://localhost:8443/content/uploadFile"
              :on-change="handleFileChange"
              :auto-upload="false"
              :file-list="fileList"
              :show-file-list="false"
              multiple
              ref="upload">
              <div slot="trigger" style="display: flex;">
                <span class="icon">
                  <svg-icon icon-class="uploadFile"></svg-icon>
                </span>
                <span class="operate">上传文件</span>
              </div>
            </el-upload>
          </li>
        </ul>
      </div>
      <span slot="reference" style="font-size: 13px">
        <i class="el-icon-plus"></i>
      </span>
    </el-popover>
    <!-- 新建文章 输入名称 -->
    <el-dialog
      title="新建节点名称"
      :visible.sync="dialogVisible"
      width="30%"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-input v-model="title" placeholder="请输入标题"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-loading="addloading" v-if="!file_type" type="primary" @click="addArticleSubmit">确 定</el-button>
        <el-button v-loading="addloading" v-else type="primary" @click="submitAddPhp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addWikiArticle } from '@/api/wikiApi.js'
import { newFile, uploadChapter } from '@/api/fileApi'
import reNameDialog from '@/components/reNameDialog/index'
import {validateFile} from '@/utils/index'

export default {
  data() {
    return {
      hasCreatePower: true,
      addloading: false,
      dialogVisible: false,
      title: '',
      file_type: null,
      fileList: []
    }
  },
  props: {
    kbId: {
      type: Number,
      defalut: null
    },
    encryptionStr: {
      type: String,
      defalut: '-1'
    }
  },
  methods: {
    handleFileChange(file) {
      if (!validateFile(file)) {
        return
      }
      file.encryptionStr = this.encryptionStr
      file.uplaodTime = new Date()
      file.kbId = this.kbId
      // this.$store.dispatch('updateUploadList', file)
      const oldFileList = this.$store.getters.uploadFileList
      oldFileList.push(Object.assign({}, file, {
        upLoadProgress: 0,
        isError: false
      }))
      this.$store.dispatch('updateUploadList', oldFileList)
    },
    handleClose() {
      this.dialogVisible = false
      this.title = ''
      this.file_type = null
    },
    addArticleBtn(type) {
      if (type) {
        this.file_type = type
      } else {
        // markdown文档
        this.file_type = null
      }
      this.dialogVisible = true
    },
    addArticleSubmit() {
      const params = {
        title: this.title,
        encryptionStr: this.encryptionStr,
        kbId: this.kbId,
        sortNo: 1
      }
      this.addloading = true
      addWikiArticle(params).then(res => {
        this.addloading = false
        this.$emit('getTreeData')
        // this.$router.push({
        //   path: '/wiki/detail/' + this.menuItemData.encryptionStr
        // })
        this.$message.success(res.message)
        this.handleClose()
      }).catch(() => {
        this.addloading = false
      })
    },
    submitAddPhp() {
      const Base64 = require('js-base64').Base64
      const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
      this.addloading = true
      newFile({
        action: 'createFile',
        file_type: this.file_type,
        code: urlCode
      }).then((res) => {
        const addParams = {
          kbId: this.kbId,
          encryptionStr: this.encryptionStr,
          title: this.title,
          sortNo: 1,
          username: this.$store.getters.name,
          type: this.file_type,
          dpath: res.data.dpath
        }
        uploadChapter(addParams).then((response) => {
          this.addloading = false
          this.title = ''
          this.$emit('addFolderSuccess')
          // this.$store.dispatch('toggleCatalogWiki', response.data)
          this.$emit('getTreeData')
          this.handleClose()
          const Base64 = require('js-base64').Base64
          const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
          const href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + res.data.dpath + `&code=${urlCode}`
          window.open(href, '_blank')
        }).catch(err => {
          this.addloading = false
          // 还需删除上传的文件
          console.log(err)
        })
      }).catch(err => {
        this.addloading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-plus {
  &:hover {
    background: #dee0e3;
  }
}

// .right-panel {
//   .operate-item {
//     height: 30px;
//     line-height: 30px;
//     border-radius: 5px;
//     margin: 0 5px;
//     cursor: pointer;
//     display: flex;
//     position: relative;
//     &:hover {
//       background-color: #ececee;
//     }
//     .icon {
//       display: block;
//       width: 24px;
//       height: 24px;
//       text-align: center;
//       line-height: 40px;
//       margin-right: 5px;
//       .svg-icon {
//         width: 1.2em;
//         height: 1.2em;
//       }
//       &.rightIcon {
//         margin-left: auto;
//       }
//     }
//     .operate {
//       display: flex;
//       align-items: center;
//     }
//   }
// }
</style>

<template>
  <el-drawer
    class="move-drawer"
    title="知识库目录调整"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose">
    <el-tree
      :data="treeDataList"
      :props="defaultProps"
      v-loading="isLoading"
      node-key="id"
      @node-drop="handleDrop"
      :load="loadNode"
      lazy
      draggable>
    </el-tree>
  </el-drawer>
</template>
<script>
import { getWikiChildData, movewikiMenu } from '@/api/wikiApi.js'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    kbId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      // drawer: false,
      treeDataList: [],
      isLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data) => {
          return data.has_child === 0
        }
      }
    };
  },
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        // 根节点
        return this.fetchChildren().then(children => {
          resolve(children);
        });
      }
      if (node.data.has_child && !node.data.children) {
        this.fetchChildren(node.data.id).then(children => {
          resolve(children);
        });
      } else {
        return resolve([]);
      }
    },
    fetchChildren(id) {
      return new Promise(resolve => {
        // 第一层节点传参 kbId: this.kbId
        getWikiChildData(id ? { parentId: id } : { kbId: this.kbId }).then(res => {
            if (res.code === '000000') {
              resolve(res.data)
            }
          }).catch(err => {
            console.log(err)
          })
      });
    },
    handleClose(done) {
      this.$emit('close')
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // dropType: before、after、inner
      console.log('11111 tree drop: ',draggingNode.label, draggingNode, dropNode.label, dropType,dropNode);
      this.handleMoveData(draggingNode, dropNode, dropType)
    },
    handleMoveData(draggingNode, dropNode, dropType) {
      this.isLoading = true
      // 获取移除的父节点 移入的父节点id 移动的节点id
      // 移入的层级结构需更改所有的排序sortNo 移到最顶层需要parent为空
      let parentChilds = []
      if (dropType === 'inner') {
        parentChilds = dropNode.data.children
      } else {
        parentChilds = dropNode.data.parentId === -1 ? dropNode.parent.data : dropNode.parent.data.children
      }
      const params = {
        moveParentId: dropType === 'inner' ? dropNode.data.id : dropNode.data.parentId,
        moveId: draggingNode.data.id,
        parentChilds: parentChilds
      }
      movewikiMenu(params).then(res => {
        this.isLoading = false
        this.$message({
          message: '移动成功',
          type: 'success'
        })
        this.$emit('getTreeData')
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.move-drawer {
  /deep/.el-drawer__header {
    margin-bottom: 22px;
    font-weight: 600;
  }
  /deep/.el-drawer__body {
    padding: 0 20px;
    .el-tree-node__content:hover, .el-upload-list__item:hover {
      background-color: #eeeff0;
      color: #0a8cff;
    }
  }
}
</style>
<template>
  <div class="nav-tree-item-group">
    <div class="nav-tree-item-name ellipsis" :style="{ 'padding-left': num*15+'px'}" :class="menuItemData.active?'active':''" @click="menuCheck">
      <span v-if="menuItemData.has_child === 0" class="opt-btn">
        <span class="zw"></span>
      </span>
      <span class="opt-btn" v-else-if="isOpen" @click.stop="toggleTree(menuItemData)">
        <svg-icon icon-class="close-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span class="opt-btn" v-else-if="!isOpen" @click.stop="toggleTree(menuItemData)">
        <svg-icon icon-class="open-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span class="sub-tree-item ellipsis" :class="menuItemData.active ? 'active' : ''">
        <span class="opt-btn">
          <svg-icon v-if="!menuItemData.type" icon-class="document" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newExcel'" icon-class="excel-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newPowerPoint'" icon-class="ppt-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newMp4'" icon-class="mp4-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newDoc'" icon-class="doc-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else icon-class="document" class="word-icon-span"></svg-icon>
        </span>
        <span class="ellipsis title" :title="menuItemData.name">{{ menuItemData.name }}</span>
      </span>
      <span class="add-icon" v-if="kbPermission === 2">
        <i class="el-icon-edit" @click="handleReName"></i>
      </span>
      <span class="add-icon" v-if="kbPermission === 2">
        <add-popover :kbId="kbId" :encryptionStr="menuItemData.encryptionStr" @getTreeData="getTreeData"></add-popover>
      </span>
    </div>
    <ul class="nav-tree-item-subTree" v-if="menuItemData.children && menuItemData.children.length > 0 && isOpen">
      <li v-for="(item, index) in menuItemData.children" :key="index">
        <menuItem :menuItemData="item" :isFirst="isFirst" :kbId="kbId" :num="num + 1" :kbPermission="kbPermission" @getTreeData="getTreeData"/>
      </li>
    </ul>
    <!-- 重命名 弹窗 -->
    <re-name-dialog
      :visible="renameVisible"
      :id="Number(reNameObj.id)"
      :name="reNameObj.name"
      @closeTab="closeReNameTab"
      @renameSuccess="renameSuccess">
    </re-name-dialog>
  </div>
</template>

<script>
import AddPopover from './addPopover.vue'
import reNameDialog from '@/components/reNameDialog/index'
import { getWikiChildData } from "@/api/wikiApi.js"

export default {
  name: 'menuItem',
  components: { AddPopover, reNameDialog },
  props: {
    kbId: {
      type: Number,
      defalut: null
    },
    menuItemData: {
      type: Object,
      default: () => {}
    },
    num: {
      type: Number,
      default: 0
    },
    kbPermission: {
      type: Number,
      default: 1
    },
    isFirst: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    // 刷新默认打开指定节点的父级节点
    // this.$nextTick(() => {
    //   if(this.menuItemData.expanded) {
    //     console.log(999911, this.isOpen)
    //     this.isOpen = true
    //   }
    // })
  },
  watch: {
    isFirst: {
      handler(val) {
        this.isOpen = false
        if(this.menuItemData.expanded) {
          this.isOpen = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      isOpen: false,
      href: '',
      renameVisible: false,
      reNameObj: {
        id: '',
        name: ''
      }
    }
  },
  methods: {
    menuCheck() {
      this.$store.dispatch('toggleCatalogWiki', this.menuItemData.id)
      this.$store.dispatch('setWikiMenuItem', this.menuItemData)
      this.$router.push({
        path: '/wiki/detail/' + this.menuItemData.encryptionStr
      })
    },
    toggleTree(item) {
      // 判断是否有子节点-有且无数据则请求接口
      if (item.has_child === 1 &&(!item.children || item.children.length === 0)) {
        getWikiChildData({
          parentId: item.id,
        }).then(res => {
          if (res.code === '000000') {
            item.children = res.data
            this.isOpen = !this.isOpen
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.isOpen = !this.isOpen
      }
    },
    getTreeData() {
      this.$emit('getTreeData')
    },
    handleReName() {
      this.renameVisible = true
      this.reNameObj.id = this.menuItemData.id
      this.reNameObj.name = this.menuItemData.name
    },
    closeReNameTab() {
      this.renameVisible = false
    },
    renameSuccess() {
      this.renameVisible = false
      this.getTreeData()
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-tree-item-group {
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  .nav-tree-item-name {
    line-height: 30px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 3px;
    border-radius: 2px;
    border: 2px solid rgba(0,0,0,0);
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    .opt-btn {
      display: inline-block;
      font-size: 16px;
      line-height: 0;
      text-align: center;
      .zw {
        display: flex;
        height: 100%;
        width:0.8em;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
      }
    }
    .opt-btn+.sub-tree-item {
      margin-left: 3px;
    }
    .word-icon-span {
      width:1em !important;
      height:1em !important
    }
    .sub-tree-item {
      display: flex;
      align-items: center;
      height: 100%;
      width: calc(100% - 20px);
      line-height: 32px;
      &.active {
        color: #f01d94;
        font-weight: 700;
      }
    }
    .title {
      display: block;
      padding-left: 5px;
      font-size: 14px;
      user-select: none;
    }
    &:hover {
      background: #dee0e3;
      .add-icon {
        display: inline-block;
        padding-right: 10px;
      }
    }
    .add-icon {
      display: none;
    }
    &.active {
      background: #dee0e3;
    }
  }
  .nav-tree-item-subTree{
    list-style-type: none;
    padding-left:0;
  }
  // .nav-tree-item-subTree > li{
  //   margin-bottom:0.2rem;
  //   margin-left:5px;
  // }
  .nav-tree-item-name{
    margin-left: 5px;
    position: relative;
  }
}
</style>

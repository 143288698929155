import request from '@/utils/request'

// 查询知识库章节树形数据
export function getTechnologyMenuFetch(params) {
  return request({
    url: '/wikiApi/getTechnologyMenu',
    method: 'get',
    params: params
  })
}
export function getWikiChildData(params) {
  return request({
    url: '/wikiApi/getTechnologyMenuByParentId',
    method: 'get',
    params: params
  })
}
// 查询知识库信息
export function getTechnologyMenuOne(params) {
  return request({
    url: '/wikiApi/getTechnologyMenuOne',
    method: 'get',
    params: params
  })
}

// 查询知识库文章详情
export function getDetail(params) {
  return request({
    url: '/wikiApi/articleDetail',
    method: 'get',
    params: params
  })
}

// 知识库添加文章
export function addWikiArticle(params) {
  return request({
    url: '/wikiApi/addWikiArticle',
    method: 'post',
    data: params
  })
}

// 查询知识库相关权限
export function getWikiPermission(params) {
  return request({
    url: '/permission/wikiPermission',
    method: 'get',
    params: params
  })
}
// 我的空间移动到知识库
export function moveToBase(params) {
  return request({
    url: '/wikiApi/moveToWiki',
    method: 'post',
    data: params
  })
}
// 知识库文章内部移动
export function movewikiMenu(params) {
  return request({
    url: '/wikiApi/wikiMoveArticle',
    method: 'post',
    data: params
  })
}

import { render, staticRenderFns } from "./moveTree.vue?vue&type=template&id=83df2cf0&scoped=true&"
import script from "./moveTree.vue?vue&type=script&lang=js&"
export * from "./moveTree.vue?vue&type=script&lang=js&"
import style0 from "./moveTree.vue?vue&type=style&index=0&id=83df2cf0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83df2cf0",
  null
  
)

component.options.__file = "moveTree.vue"
export default component.exports